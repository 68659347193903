import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Layout from '../containers/layout'
import PortableText from '../components/portableText'
import { TherapistCard } from '../components/cards/therapistCard'

export const query = graphql`
  query TherapeutenPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    },
    page: sanityTherapistOverviewPage {
		id
        slug {
          current
        }
        showBanner
        seoTitle
        seoKeywords
        hideOverlay
        seoDescription
        bannerTitle
        bannerSubTitle
        bannerImage {
          asset {
            url
            fluid {
				...GatsbySanityImageFluid
			}
          }
        }
        bannerCta {
          label
          url
        }
    },
    therapistPages: allSanityTherapistPage {
        edges {
          node {
            slug {
              current
            }
            therapist {
              _rawDescription
              firstName
              lastName
              location
              specialisations
              profilePhoto {
                asset {
                  url
                  fluid {
                    ...GatsbySanityImageFluid
                }
                }
              }
            }
          }
        }
      }
  }
`

const TherapeutenPage = ({ data = {}, errors }) => {

	if (errors) {
		return (
			<Layout>
				<GraphQLErrorList errors={errors} />
			</Layout>
		)
	}

	const site = data.site || {}
	const page = data.page || {}
	const pages = (data.therapistPages || {}).edges || []

	if (!site) {
		throw new Error(
			'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
		)
	}

	return (
		<Layout page={page} >
			<Container>
				<section className="py-16 px-4 4xl:px-0 bg-white bg-gray-50">
					<div className=" max-w-6xl mx-auto px-4">
						<h3 className="font-roboto-slab text-3xl text-gray-900">Dit zijn onze therapeuten</h3>
						<div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">

							{
								// Hardcoded removal of Giel. Ugly
								pages.filter(({ node }) => {
									return node.therapist && node.therapist.lastName !== "Dietzenbacher"
								}).map(({ node }) => {


									return (
										<TherapistCard node={node} />
									)
								})
							}

						</div>
					</div>
				</section>
				<section className="py-16 px-4 4xl:px-0 bg-orange-400">
					<div className="sm:flex flex-row justify-between items-center max-w-6xl mx-auto px-4">
						<div>
							<h2 className="font-roboto-slab text-white text-2xl md:text-4xl">Partner worden?</h2>
							<p className="text-xl text-orange-100">Partners genieten van extra voordelen.</p>
						</div>
						<div className=" mt-8 sm:mt-0 ">
							<Link to="/partners" className="block px-8 py-4 text-orange-400 text-lg rounded-lg bg-white rounded-lg uppercase tracking-widest">Meer informatie</Link>
						</div>
					</div>
				</section>
			</Container>
		</Layout>
	)
}

export default TherapeutenPage
