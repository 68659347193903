import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import PortableText from '../portableText'

export const TherapistCard = ({ node = {} }) => {
    const {
        therapist = {},
        slug = {}
    } = node

    const { current } = slug

    const {
        firstName,
        lastName,
        specialisations = [],
        location,
        profilePhoto = {},
        _rawDescription
    } = therapist

    const { asset } = profilePhoto
    const { url, fluid } = asset

    return (
        <Link to={current} className="flex flex-col rounded-lg overflow-hidden shadow-lg">
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                    <div className="md:flex flex-row">
                        <div>
                            <div className="block h-24 w-24">
                                <Img className="block h-24 w-24 w-full object-cover rounded-full" fluid={fluid} alt="" />
                            </div>
                        </div>
                        <div className="flex flex-col justify-center mt-4 md:mt-0 md:ml-4 text-sm leading-5 font-medium text-orange-400">
                            <span className="hover:underline">
                                {location}
                            </span>
                            <h3 className="font-roboto-slab text-2xl leading-7 font-semibold text-gray-900">
                                {firstName + " " + lastName}
                            </h3>
                        </div>
                    </div>
                    <div className="block">

                        <div className="mt-4 text-base leading-6 text-gray-500">
                            <PortableText blocks={_rawDescription} />
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center">
                    {
                        specialisations.map((tag) => {
                            return (
                                <div className="mr-4">
                                    <a href="#" className="inline-block">
                                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-orange-100 text-orange-800">
                                            {tag}
                                        </span>
                                    </a>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </Link>
    )
}