import { graphql, StaticQuery } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../components/layout'

function LayoutContainer(props) {
	const [showNav, setShowNav] = useState(false)
	function handleShowNav() {
		setShowNav(true)
	}
	function handleHideNav() {
		setShowNav(false)
	}

	return (
		<Layout
			{...props}
			showNav={showNav}
			// siteTitle={props.siteTitle || data.site.title}
			// subTitle={props.subTitle || ""}
			onHideNav={handleHideNav}
			onShowNav={handleShowNav}
		/>
	)
}

export default LayoutContainer
